import { render, staticRenderFns } from "./MiniApp.vue?vue&type=template&id=4f80271c&lang=pug&"
import script from "./MiniApp.vue?vue&type=script&lang=js&"
export * from "./MiniApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VSheet})
