<template lang="pug">
v-dialog(v-model="showDialog" :persistent="loading" :fullscreen="$vuetify.breakpoint.xsOnly" :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'" width="400")
  v-card.d-flex.flex-column(flat :min-height="$vuetify.breakpoint.xsOnly ? null : 500")
    v-toolbar(flat style="flex: 0" v-if="$vuetify.breakpoint.xsOnly")
      v-btn(icon @click="showDialog = false" :disabled="loading")
        v-icon mdi-close
      v-toolbar-title {{ title }}
    div.d-flex.justify-center.align-center(style="flex: 3")
      h1.px-2.text-center {{ subtitle }}
    div.d-flex.justify-center.align-center.grey_--text(style="flex: 1; font-size: 3rem; font-family: monospace; letter-spacing: 1rem; padding-left: 1rem")
      div(:class="{'shake' : errorText}") {{ mask }}
    div.d-flex.justify-center.align-center(flat style="flex: 1")
      p.px-2.text-center(:class="{'red--text' : errorText}") {{ errorText ? errorText : text }}
    v-progress-linear.mt-4.mb-2(active :indeterminate="loading" color="secondary")
    div.d-flex(style="flex: 9" class="pinpad")
      div.d-flex.flex-column(style="flex: 4")
        div.d-flex(style="flex: 1")
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('1')" :disabled="loading") 1
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('2')" :disabled="loading") 2
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('3')" :disabled="loading") 3
        div.d-flex(style="flex: 1")
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('4')" :disabled="loading") 4
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('5')" :disabled="loading") 5
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('6')" :disabled="loading") 6
        div.d-flex(style="flex: 1")
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('7')" :disabled="loading") 7
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('8')" :disabled="loading") 8
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('9')" :disabled="loading") 9
        div.d-flex(style="flex: 1")
          div(style="flex: 1")
            v-btn.pa-0.text-h6
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="digit('0')" :disabled="loading") 0
          div(style="flex: 1")
            v-btn.pa-0.text-h6(@click="backspace()" :disabled="loading")
              v-icon mdi-backspace-outline

</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    errorText: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showDialog: false,

    pincode: '',
    mask: '____',
    timerId: null,
  }),

  watch: {
    value(value) {
      if (value) {
        this.reset()
        this.showDialog = true
        window.addEventListener('keydown', this.keyListener, false)
      } else {
        this.showDialog = false
        window.removeEventListener('keydown', this.keyListener, false)
      }
    },

    pincode(value) {
      if (!value) value = ''
      if (value.length < 4) {
        value = value.padEnd(4, '_')
      }
      this.mask = value.replace(/\d/g, '•')
    },

    errorText(value) {
      if (value) {
        if (window.cordova !== undefined) {
          navigator.vibrate(300)
        }
        this.timerId = setTimeout(() => {
          this.reset()
        }, 1500)
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
        window.removeEventListener('keydown', this.keyListener, false)
      }
    },
  },

  methods: {
    keyListener(event) {
      const reg = new RegExp('^[0-9]$')
      if (reg.test(event.key) && !this.loading) {
        this.digit(event.key)
      } else if (event.key === 'Backspace' && !this.loading) {
        this.backspace()
      }
    },

    digit(digit) {
      this.playClickSound()
      if (this.errorText) {
        clearTimeout(this.timerId)
        this.reset()
      }
      this.pincode += digit
      if (this.pincode && this.pincode.length === 4) {
        this.$emit('action', this.pincode)
      }
    },

    backspace() {
      this.playClickSound()
      if (this.errorText) {
        clearTimeout(this.timerId)
        this.reset()
      }
      this.pincode = this.pincode.substring(0, this.pincode.length - 1)
    },

    reset() {
      this.pincode = ''
      this.mask = '____'
      this.$emit('update:errorText', null)
    },

    playClickSound() {
      if (this.$isCordova()) {
        window.nativeclick.trigger()
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.pinpad .v-btn
  height 100%
  width 100%
.pinpad .v-btn:active
  opacity 0.6 !important
  transition-property opacity
  transition-duration 0s
</style>
