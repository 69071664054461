import Vue from 'vue'
import VueRouter from 'vue-router'
import { onAuthStateChanged } from 'firebase/auth'
import store from '../store'
import SignIn from '../components/auth/SignIn.vue'
import SignInFFBPos from '../components/auth/SignInFFBPos.vue'
import NavBar from '../components/NavBar.vue'
import Dashboard from '../components/Dashboard.vue'
import Settings from '../components/settings/Settings.vue'
import TicketDetails from '../components/ticket/TicketDetails.vue'
import TicketDraft from '../components/ticket/TicketDraft.vue'
import TicketPrint from '../components/ticket/TicketPrint.vue'
import UPlusCart from '../components/uplus/Cart.vue'
import UPlusPayment from '../components/uplus/PaymentType.vue'
import Shift from '../components/shift/Shift.vue'
import Shifts from '../components/shift/Shifts.vue'
import ZXReport from '../components/shift/ZXReport.vue'
import SectionsReport from '../components/shift/SectionsReport.vue'
import OperatorsReport from '../components/shift/OperatorsReport.vue'
import FiscalReport from '../components/shift/FiscalReport.vue'
import CashRegister from '../components/settings/CashRegister.vue'
import AccessUsers from '../components/settings/AccessUsers.vue'
import Token from '../components/settings/Token.vue'
import Pincode from '../components/settings/Pincode.vue'
import Activation from '../components/settings/Activation.vue'
import Deactivation from '../components/settings/Deactivation.vue'
import Restore from '../components/settings/Restore.vue'
import Printer from '../components/settings/Printer.vue'
import Ui from '../components/settings/Ui.vue'
import Receipt from '../components/settings/Receipt.vue'
import Services from '../components/services/Services.vue'
import FreedomBox from '../components/services/FredomBox.vue'
import FreedomCredit from '../components/services/FredomCredit.vue'
import FreedomLife from '../components/services/FredomLife.vue'
import FreedomInsuranceCasco from '../components/services/FredomInsuranceCasco.vue'
import KaspiShopSettings from '../components/services/KaspiShop.vue'
import HalykPosSettings from '../components/services/HalykPos.vue'
import MarketplaceRequest from '../components/services/MarketplaceRequest.vue'
import MoyuchetSettings from '../components/services/Moyuchet.vue'
import AtbPos from '../components/services/AtbPos.vue'
import Tcis from '../components/services/Tcis.vue'
import Positions from '../components/settings/Positions.vue'
import AccessApi from '../components/settings/AccessApi.vue'
import Profile from '../components/settings/Profile.vue'
import LegalAndRegulatory from '../components/settings/LegalAndRegulatory.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/signin',
    name: 'SignIn',
    meta: { requiresAuth: false },
    component: process.env.VUE_APP_FFB_POS_MODE === 'true' ? SignInFFBPos : SignIn,
  },
  {
    path: '/',
    component: NavBar,
    alias: ['/list', '/ofdreg'],
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/kkm',
        name: localStorage.getItem('rekassa.kz-ui-home-page') === null || localStorage.getItem('rekassa.kz-ui-home-page') === 'CALCULATOR' || localStorage.getItem('rekassa.kz-ui-home-page') === 'MAIN' ? 'MainCalculator' : 'MainPositions',
        component: () => {
          if (localStorage.getItem('rekassa.kz-ui-home-page') === null || localStorage.getItem('rekassa.kz-ui-home-page') === 'CALCULATOR' || localStorage.getItem('rekassa.kz-ui-home-page') === 'MAIN') {
            return import('../components/ticket/Calculator.vue')
          }
          return import('../components/ticket/parts/TicketDetailsMain.vue')
        },
      },
      {
        path: '/shifts/:cashRegisterId',
        name: 'Shifts',
        component: Shifts,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
      },
    ],
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/marketplaces',
    name: 'Marketplaces',
    component: Services,
  },
  {
    path: '/details',
    name: 'TicketDetails',
    component: TicketDetails,
  },
  {
    path: '/uplus/cart',
    name: 'UPlusCart',
    component: UPlusCart,
  },
  {
    path: '/uplus/payment',
    name: 'UPlusPayment',
    component: UPlusPayment,
  },
  {
    path: '/draft',
    name: 'TicketDraft',
    component: TicketDraft,
  },
  {
    path: '/print/:cashRegisterId/:ticketId',
    name: 'TicketPrint',
    component: TicketPrint,
    props: true,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber',
    name: 'Shift',
    component: Shift,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber/zxreport',
    name: 'ZXReport',
    component: ZXReport,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber/sections',
    name: 'SectionsReport',
    component: SectionsReport,
  },
  {
    path: '/shifts/:cashRegisterId/:shiftNumber/operators',
    name: 'OperatorsReport',
    component: OperatorsReport,
  },
  {
    path: '/kkm/settings',
    alias: '/kkm/fdo/payment-methods',
    name: 'CashRegister',
    component: CashRegister,
  },
  {
    path: '/receipt/settings',
    name: 'Receipt',
    component: Receipt,
  },
  {
    path: '/printer/settings',
    name: 'Printer',
    component: Printer,
  },
  {
    path: '/ui/settings',
    name: 'Ui',
    component: Ui,
  },
  {
    path: '/kkm/token',
    name: 'Token',
    component: Token,
  },
  {
    path: '/kkm/pincode',
    name: 'Pincode',
    component: Pincode,
  },
  {
    path: '/kkm/activation/:newCashRegisterId',
    name: 'Activation',
    component: Activation,
  },
  {
    path: '/kkm/deactivation',
    name: 'Deactivation',
    component: Deactivation,
  },
  {
    path: '/kkm/restore',
    name: 'Restore',
    component: Restore,
  },
  {
    path: '/kkm/access-users',
    name: 'AccessUsers',
    component: AccessUsers,
  },
  {
    path: '/kkm/access-api',
    name: 'AccessApi',
    component: AccessApi,
  },
  {
    path: '/kkm/freedom/box',
    name: 'FreedomBox',
    component: FreedomBox,
  },
  {
    path: '/kkm/freedom/credit',
    name: 'FreedomCredit',
    component: FreedomCredit,
  },
  {
    path: '/kkm/freedom/insurance/casco',
    name: 'FreedomInsuranceCasco',
    component: FreedomInsuranceCasco,
  },
  {
    path: '/kkm/freedom/life',
    name: 'FreedomLife',
    component: FreedomLife,
  },
  {
    path: '/kkm/kaspi/shop',
    name: 'KaspiShopSettings',
    component: KaspiShopSettings,
  },
  {
    path: '/kkm/halyk/pos',
    name: 'HalykPosSettings',
    component: HalykPosSettings,
  },
  {
    path: '/kkm/moyuchet',
    name: 'MoyuchetSettings',
    component: MoyuchetSettings,
  },
  {
    path: '/kkm/uchet/tcis',
    name: 'Tcis',
    component: Tcis,
  },
  {
    path: '/kkm/atbpos',
    name: 'AtbPos',
    component: AtbPos,
  },
  {
    path: '/kkm/marketplace-request',
    name: 'MarketplaceRequest',
    component: MarketplaceRequest,
  },
  {
    path: '/kkm/positions',
    name: 'Positions',
    component: Positions,
  },
  {
    path: '/reports/fiscal',
    name: 'FiscalReport',
    component: FiscalReport,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/kkm/legal-and-regulatory',
    name: 'LegalAndRegulatory',
    component: LegalAndRegulatory,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const goSignin = (to, from, next) => {
  window.scrollTo(0, 0)
  next({ path: '/signin', query: { from: to.fullPath } })
}

const goNext = (next, path) => {
  window.scrollTo(0, 0)
  if (path) next(path)
  else next()
}

const getCurrentUser = () => new Promise((resolve) => {
  onAuthStateChanged(store.state.auth.firebaseAuth, (user) => {
    resolve(user)
  })
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.user !== null
  if (to.meta.requiresAuth !== false && !isAuthenticated) {
    // Cordova
    if (window.cordova !== undefined) {
      if (!navigator.onLine) {
        store.dispatch('tools/setConnectionWarning', true)
      }

      window.FirebasePlugin.getCurrentUser((user) => {
        if (user) {
          store.dispatch('auth/setUser', user).then(() => {
            store.dispatch('cashRegisters/init', to).then(() => {
              goNext(next, to.path === '/' && (localStorage.getItem('rekassa.kz-ui-home-page') === 'CALCULATOR' || localStorage.getItem('rekassa.kz-ui-home-page') === 'POSITIONS') ? '/kkm' : null)
            })
          })
        } else {
          goSignin(to, from, next)
        }
      }, () => {
        goSignin(to, from, next)
      })

    // Web
    } else {
      getCurrentUser().then((user) => {
        if (user) {
          store.dispatch('auth/setUser', user).then(() => {
            store.dispatch('cashRegisters/init', to).then(() => {
              goNext(next, to.path === '/' && (localStorage.getItem('rekassa.kz-ui-home-page') === 'CALCULATOR' || localStorage.getItem('rekassa.kz-ui-home-page') === 'POSITIONS') ? '/kkm' : null)
            })
          })
        } else {
          goSignin(to, from, next)
        }
      }, () => {
        goSignin(to, from, next)
      })
    }
  } else {
    goNext(next)
  }
})

// Track screens
router.afterEach((to) => {
  store.dispatch('analytics/setScreenName', to.name)
})

export default router
