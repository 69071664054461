<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/kkm')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("выбор_товара_услуги") }}
    v-progress-linear(:active="positionsLoading" :indeterminate="positionsLoading" absolute top color="secondary")

  v-main
    v-container.d-md-flex.wrapper.py-md-15.pt-6.pb-15

      div.col-md-6.pa-0.d-flex.flex-column.mx-md-0.flex-shrink-1
        re-products-list(:products="cartList" @increaseQuantity="increaseQuantity" @decreaseQuantity="decreaseQuantity" @removeItemFromCart="removeItemFromCart")

        v-btn(height="72" max-height="72" elevation="0" color="secondary" @click="next()" :disabled="cartList.length === 0").col-12.mt-auto.d-none.d-md-flex
          div.d-flex.justify-space-between.col-12.pa-0
            p.font-weight-bold.text-h3 {{ $t("далее") }}
            p.font-weight-bold.text-h3 {{ totalPrice | numeral('0,0.[00]') }} ₸

      v-sheet(:class="['px-3 pb-6 pt-3 rounded-t-xl d-flex flex-column nav-item col-12 col-md-6', isFullHeight ? 'full-height' : 'add-item-card']")
        div(@touchstart="handleTouchStart" @touchend="handleTouchEnd")
          div.thumb.mb-5

        v-row(height="fit-content").pa-0.px-3.mt-md-2.flex-grow-0.flex-nowrap
          v-btn(v-if="type === 'product'" :min-width="searchSize" :width="searchSize" elevation="0" color="reBackground lighten-1" @click.left="back()").mr-2
            v-icon mdi-arrow-left
          v-text-field(v-model="searchText" placeholder="Поиск по наименованию/штрих-коду" filled autocomplete="off" clearable).rounded-xl
          v-btn(v-if="$isCordova()" :min-width="searchSize" :width="searchSize" elevation="0" color="primary" @click="scanBarcode()").ml-2
            v-icon.flex-grow-0 mdi-barcode-scan

        div(v-if="type === 'category' && positionList.length > 0" @scroll="onScroll").btns-wrapper
          re-select-category-item(v-for="(item, index) in positionsCategories" :key="index" :category-item="item" @click.native="selectCategory(item)")
          v-btn(elevation="0" color="reBackground lighten-1" :height="addCategoryBtn" @click="addCategory()").py-3.py-md-6
            div.d-flex.flex-column.align-center.justify-center
              v-icon(:size="plusIconSize").flex-grow-0.mb-1 mdi-plus
              p.text-body-2.text-md-h5(v-html="$t('добавить_категорию')")

        div(v-if="type === 'product' || positionList.length === 0" @scroll="onScroll").btns-wrapper
          re-select-product-item(v-for="(item, index) in limitedFiltredPositionList" :key="index" :product-item="item" :is-active="index === 0" :quantityCounters="quantityCounters" @click="addItemToCart(item)")
          v-btn(elevation="0" color="reBackground lighten-1" :height="addProductBtn" @click="addPosition()").py-3.py-md-6
            div.d-flex.flex-column.align-center.justify-center
              v-icon(:size="plusIconSize").flex-grow-0.mb-1 mdi-plus
              p.text-body-2.text-md-h5(v-html="$t('добавить_товар_услугу')")

        div(:class="[isBottom ? 'next-btn-wrapper-bottom' : 'next-btn-wrapper', 'd-md-none']" v-if="cartList.length > 0")
          v-container(style="height: 100%").d-flex.pb-6
            v-btn(elevation="0" color="secondary" @click="next()").col-12.mt-auto.d-md-none.d-flex
              div.d-flex.justify-space-between.col-12.pa-0
                p.font-weight-bold.text-h5 {{ $t("далее") }}
                p.font-weight-bold.text-h5 {{ totalPrice | numeral('0,0.[00]') }} ₸

  re-mini-app(v-model="showMiniAppUPlus" :url="miniAppUplusUrl" :params="miniAppUplusParams" name="inventory-management")

</template>

<script>
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'
import ProductsList from './parts/ProductsList.vue'
import SelectProductItem from './parts/SelectProductItem.vue'
import SelectCategoryItem from './parts/SelectCategoryItem.vue'
import MiniApp from '../utils/MiniApp.vue'

export default {
  components: {
    're-products-list': ProductsList,
    're-select-product-item': SelectProductItem,
    're-select-category-item': SelectCategoryItem,
    're-mini-app': MiniApp,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    searchText: null,
    selectedCategory: null,
    cartList: [],
    quantityCounters: {},

    showMiniAppUPlus: false,
    miniAppUplusUrl: process.env.VUE_APP_MINIAPP_UPLUS_URL,
    miniAppUplusParams: [
      { viewType: 'home' },
    ],

    type: 'category',
    isBottom: false,
    isFullHeight: false,
    lastScrollTop: 0,
    touchstartY: 0,
    touchendY: 0,
    swipeThreshold: 30,

    scrollLimit: 30,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      categoryList: state => state.positions.categories,
      positionList: state => state.positions.list,
      positionsLoading: state => state.positions.loading,
    }),

    limitedFiltredPositionList() {
      return this.filtredPositionList.slice(0, this.scrollLimit)
    },

    // filter positions by search text
    filtredPositionList() {
      if (this.searchText === null || this.searchText === '') {
        if (this.selectedCategory) {
          if (this.selectedCategory.id === 'no-category') {
            return this.positionList.filter((item) => item.category === undefined)
          }
          return this.positionList.filter((item) => item.category === this.selectedCategory.id)
        }
        return this.positionList
      }
      return this.positionList.filter((item) => !this.searchText || item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || (item.barcode === this.searchText))
    },

    positionsCategories() {
      return this.categoryList.filter(category => (category.id === 'no-category' && this.positionList.some(position => (position.category === undefined)))
        || this.positionList.some(position => position.category === category.id))
    },

    // calculate total price of all items in cartList
    totalPrice() {
      return this.cartList.reduce((acc, item) => acc + item.totalPrice, 0)
    },

    /* ------------------ Styles ------------------ */
    addPositionBtnHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '72' : '48'
    },
    iconSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '54' : '24'
    },
    searchSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '60' : '48'
    },
    plusIconSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '40' : '25'
    },
    addProductIcon() {
      return this.$vuetify.breakpoint.mdAndUp ? '30' : '20'
    },
    addCategoryBtn() {
      return this.$vuetify.breakpoint.mdAndUp ? '120' : '86'
    },
    addProductBtn() {
      return this.$vuetify.breakpoint.mdAndUp ? '172' : '110'
    },
  },

  watch: {
    showMiniAppUPlus(value) {
      if (!value) {
        this.init()
      }
    },

    searchText(value) {
      if (value === null || value === '') {
        this.type = 'category'
      } else {
        this.type = 'product'
      }
    },
  },

  created() {
    this.init()

    this.cartList = this.$store.state.positions.cartList
  },

  methods: {
    ...mapActions({
      fetchCategories: 'positions/fetchCategories',
      fetchPositions: 'positions/fetch',
      setCartList: 'positions/setCartList',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    addCategory() {
      this.miniAppUplusParams = [
        { viewType: 'categories' },
        { viewMode: 'edit' },
      ]
      this.showMiniAppUPlus = true
    },

    addPosition() {
      this.miniAppUplusParams = [
        { viewType: 'products' },
        { viewMode: 'edit' },
      ]
      this.showMiniAppUPlus = true
    },

    init() {
      // fetch positions for current cash register
      this.fetchPositions(this.cashRegister.id).then(() => {}).catch(() => {})
      // fetch categories for current cash register
      this.fetchCategories(this.cashRegister.id).then(() => {}).catch(() => {})
    },

    scanBarcode() {
      const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
      window.cordova.plugins.rekassaBarcode.scan((barcode) => {
        this.searchInPositionList(barcode)
      },
      () => {}, { aspectRatio })
    },

    searchInPositionList(barcode) {
      const filterList = this.positionList.filter((item) => item.barcode === `${barcode}`)
      if (filterList.length === 1) {
        if (filterList[0].price > 0) {
          this.addItemToCart(filterList[0])
          this.playSound('found')
        } else {
          this.playSound('error')
        }
      } else if (filterList.length > 1) {
        const doublicates = filterList.reduce((items, item, index) => {
          if (index === 0) {
            items = item.name
          } else {
            items = `${items}, ${item.name}`
          }
          return items
        }, [])
        this.showSnackbar({ message: this.$t('найдено_больше_одной_записи_с_данным_штрих_кодом', { items: doublicates }) })
        this.playSound('error')
      } else if (filterList.length === 0) {
        this.showSnackbar({ message: this.$t('позиция_с_данным_штрих_кодом_не_найдена'), timeout: 2000 })
        this.playSound('error')
      }
    },

    selectCategory(category) {
      this.selectedCategory = category
      this.type = 'product'
    },

    back() {
      this.selectedCategory = null
      this.searchText = null
      this.type = 'category'
      this.scrollLimit = 30
    },

    addItemToCart(item) {
      // add item to cartList but if it is already in cartList then increase the quantity
      const index = this.cartList.findIndex((cartItem) => cartItem.id === item.id)
      if (index === -1) {
        this.cartList.unshift({
          id: item.id,
          name: item.name,
          unitType: item.unitType,
          price: item.price,
          quantity: 1,
          totalPrice: item.price * 1,
        })
        this.$set(this.quantityCounters, item.id, 1)
      } else {
        this.cartList[index].quantity += 1
        this.cartList[index].totalPrice = this.cartList[index].price * this.cartList[index].quantity
        this.$set(this.quantityCounters, item.id, this.cartList[index].quantity)
      }

      this.searchText = null

      this.analyticsLogEvent({ eventName: 're_inventory_added_item_list' })
    },

    removeItemFromCart(item) {
      // remove item from cartList
      const index = this.cartList.findIndex((cartItem) => cartItem.id === item.id)
      this.cartList.splice(index, 1)
      this.$delete(this.quantityCounters, item.id)
    },

    increaseQuantity(item) {
      // increase quantity
      const index = this.cartList.findIndex((cartItem) => cartItem.id === item.id)
      this.cartList[index].quantity += 1
      this.cartList[index].totalPrice = this.cartList[index].price * this.cartList[index].quantity
      this.$set(this.quantityCounters, item.id, this.cartList[index].quantity)
    },

    decreaseQuantity(item) {
      // decrease quantity but if quantity is 1 then remove the item from cartList
      const index = this.cartList.findIndex((cartItem) => cartItem.id === item.id)
      if (this.cartList[index].quantity === 1) {
        this.removeItemFromCart(item)
      } else {
        this.cartList[index].quantity -= 1
        this.cartList[index].totalPrice = this.cartList[index].price * this.cartList[index].quantity
        this.$set(this.quantityCounters, item.id, this.cartList[index].quantity)
      }
    },

    next() {
      if (this.totalPrice === 0) {
        this.showSnackbar({ message: this.$t('добавьте_как_минимум_один_товар_услугу'), timeout: 1000 })
        return
      }

      this.setCartList(this.cartList)
      this.$router.push('/uplus/payment')

      this.analyticsLogEvent({ eventName: 're_inventory_confirmed_selected_items' })
    },

    /* ------------------ UI Methods ------------------ */
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop < this.lastScrollTop) {
        this.isBottom = false
      }
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.isBottom = true
        if (this.type === 'product') {
          this.scrollLimit += 20
        }
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    },
    makeFullHeight() {
      this.isFullHeight = !this.isFullHeight
      this.onScroll()
    },
    handleTouchStart(event) {
      this.touchstartY = event.changedTouches[0].screenY
    },
    handleTouchEnd(event) {
      this.touchendY = event.changedTouches[0].screenY
      this.handleGesture()
    },
    handleGesture() {
      if (Math.abs(this.touchendY - this.touchstartY) > this.swipeThreshold) {
        if (this.touchendY < this.touchstartY) {
          this.isFullHeight = true
          this.onScroll()
        }
        if (this.touchendY > this.touchstartY) {
          this.isFullHeight = false
          this.onScroll()
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.next-btn-wrapper-bottom .container {
    padding: 20px 0 0 0 !important;
}

.next-btn-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: linear-gradient(0deg, var(--v-reBackground-base) 50%, rgba(38, 84, 93, 0) 100%);
    height: 120px;
}

.btns-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    overflow-y: scroll;
    grid-auto-rows: max-content;

    @media screen and (min-width: 750px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
        height: 60vh;
    }
}

.add-item-card {
    height: 65vh;
    transition: all 0.15s ease-out;

    @media screen and (min-width: 960px) {
        height: 100%;
    }
}

.full-height {
    transition: all 0.15s ease-out;
    height: calc(100% - 70px);
}

.full-height .btns-wrapper {
    padding-bottom: 10px;
}

.max-width {
    max-width: 212px;

    @media screen and (min-width: 960px) {
        max-width: 340px;
    }
}

.wrapper {
    @media screen and (min-width: 960px) {
        gap: 50px;
        height: 100%;
    }
}

.nav-item {
    position: fixed;
    bottom: 0;
    left: 0;

    @media screen and (min-width: 960px) {
        position: initial;
    }
}

.thumb {
    background: var(--v-secondary-base);
    width: 70px;
    height: 4px;
    border-radius: 10px;
    margin: 0 auto;
    flex-shrink: 0;

    @media (min-width: 960px) {
        display: none;
    }
}
</style>
